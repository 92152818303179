import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Endscenario1 from '../images/Endscenario-1.png';
import Endscenario2 from '../images/Endscenario-2.png';
import Endscenario3 from '../images/Endscenario-3.png';
import Endscenario4 from '../images/Endscenario-4.png';
import ReplayIcon from '@material-ui/icons/Replay';
import { BG_transparent } from "../constants";
import React, { useEffect } from "react";
import { getStats } from "../utils/HandleDataStorage";

export type FinalStats = {
    poison: {
        took: number;
        total: number;
    };
    good: {
        took: number;
        total: number;
    }
}

export type FinalizeProps = {
    backToStart: any,
    userId?: string
}

export function TabletFinalize({ backToStart, userId }: FinalizeProps) {
    const { t } = useTranslation();

    let [stats, setStats] = React.useState({ poison: { took: 0, total: 0 }, good: { took: 0, total: 0 } })

    let img = Endscenario1;
    let txt = "finalscreen1";

    useEffect(() => {
        getStats(userId).then((stats) => {
            setStats(stats)
        })

        // automatically return to start after 10s
        const timer = setTimeout(() => {
            backToStart();
        }, 10000)
        return () => {
            clearTimeout(timer);
        }
        // eslint-disable-next-line
    }, []);

    if (stats.good.took === 0 && stats.poison.took === 0) {
        // keinen eingesammelt
        img = Endscenario4;
        txt = "finalscreen4";
    } else if (stats.good.took === stats.good.total && stats.poison.took === 0) {
        // Alle guten eingesammelt & keinen giftigen
        img = Endscenario1;
        txt = "finalscreen1";
    } else if (stats.good.took < stats.good.total && stats.poison.took === 0) {
        // Manche guten und keine giftigen
        img = Endscenario2;
        txt = "finalscreen2";
    } else if (stats.poison.took > 0) {
        // Giftige dabei
        img = Endscenario3;
        txt = "finalscreen3";
    }

    return <div
        style={{
            width: '100%',
            height: '100%',
            backgroundColor: BG_transparent
        }}>
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'row'
            }}
        >
            <div style={{
                backgroundImage: `url("${img}")`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                height: "50vw",
                width: "50vw",
                margin: "auto",
            }}
            ></div>
            <div style={{ flex: 1, margin: '10vh' }}>
                <Typography style={{ fontSize: '4.5vh', fontWeight: 900 }}>{t(txt)}</Typography>
                <Typography style={{ marginTop: '10vh', fontSize: '3vh', fontWeight: 700 }}>{t("finished")}</Typography>
            </div>
        </div>
        
        <div onClick={backToStart} style={{
            position: 'absolute',
            right: '0vh',
            bottom: '0vh',
            color: "black",
        }}><ReplayIcon></ReplayIcon></div>
    </div>
}