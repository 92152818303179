
// Huawei Mediapad T5 Resolution: 1920 x 1200

// CONSTANTS
export const DEV_MODE = false; // if true, surveys are skipped automatically,...


// const predItems = [3,4,5,7,8,9,12,13,14,15]; // ids of possible mushroom items
const predItemsDict = [
  {id: 3, edible: false},
  {id: 4, edible: true},
  {id: 5, edible: true},
  {id: 7, edible: false},
  {id: 8, edible: true},
  {id: 9, edible: true},
  {id: 12, edible: false},
  {id: 13, edible: true},
  {id: 14, edible: true},
  {id: 15, edible: false}
]
const nrItems = 4; // how many items should be drawn randomly
export const groups = ["noexpl", "expl", "expl-gradcam", "expl-nn", "expl-dissect"]


export function getUserId() {
  const urlParams = new URLSearchParams(window.location.search);
  const userParam = urlParams.get('user');

  return userParam == null ? undefined : userParam;
}

export function removeUser(userId?: string){
  if(userId != null)
    window.localStorage.removeItem(userId);
}

function getUserFromLocalStorage(userId?: string){
  // creates new user and initializes the set of random mushrooms that a user receives
  // returns the milestone where the current user is (newly created; finished)
  // returns userid
  // returns user group
  let user;
  if(userId != null){
    let user_str = window.localStorage.getItem(userId);
    if(user_str != null){
      user = JSON.parse(user_str);
    }
  }
  if(user == null){
    user = {
        "group": Math.floor(Math.random()*groups.length),
        "id": Math.random().toString(16).substring(2, 8),
        "newlyCreated": true,
        "finished": false,
        "choices": getRandom(predItemsDict.map((value) => value.id), nrItems),
        "choiceIndex": 0,
        "classifications": new Array(nrItems),
      };
    window.localStorage.setItem(user.id, JSON.stringify(user));
  }

  return user;
}

export async function getUser(userId?: string) {
  return getUserFromLocalStorage(userId)
}

export function logStart(userId?: string, lang?:string){
  let user = getUserFromLocalStorage(userId);
  user.startTime = Date.now();
  user.language = lang;
  window.localStorage.setItem(user.id, JSON.stringify(user));
}

export function logSurvey(userId?: string, survey?: object){
  let user = getUserFromLocalStorage(userId);
  user.survey = survey;
  window.localStorage.setItem(user.id, JSON.stringify(user));
}

function getRandom(arr: number[], n: number) {
  var result = new Array(n),
      len = arr.length,
      taken = new Array(len);
  if (n > len)
      throw new RangeError("getRandom: more elements taken than available");
  while (n--) {
      var x = Math.floor(Math.random() * len);
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
}

export async function getNextPrediction(userId: string) {
  // returns id of mushroom to be classified
  // returns number of current step and max steps
  
  let user = await getUser(userId);
  
  return {
    "predictionId": user.choices[user.choiceIndex],
    "step": user.choiceIndex+1,
    "maxSteps": user.choices.length,
  }
}

export async function classifyAndNext(userId: string, step: number, value: boolean[]) {
  // save values
  // go to next prediction

  let user = await getUser(userId);
  user.newlyCreated = false;
  if(user.choiceIndex < user.choices.length){
    user.classifications[user.choiceIndex] = value;
  }else{
    user.finished = true;
  }
  user.choiceIndex += 1;
  window.localStorage.setItem(user.id, JSON.stringify(user));
  
}

export async function getStats(userId?: string){
  let user = await getUser(userId);

  let result = { poison: { took: 0, total: 0 }, good: { took: 0, total: 0 } }
  for(let i = 0; i < user.choices.length; i++){
    let item_id = user.choices[i];
    let edible = predItemsDict.find((item) => item.id === item_id)?.edible;
    let took = user.classifications[i][1];
    if(edible){
      result["good"]["total"] += 1;
      if(took){
        result["good"]["took"] += 1;
      }
    }else{
      result["poison"]["total"] += 1;
      if(took){
        result["poison"]["took"] += 1;
      }
    }
  }

  return result;
}

export function downloadResult(userId?: string) {

  let user = getUserFromLocalStorage(userId);
  if(user.survey == null) // survey was not finished -> don't download file
    return;
  user.endTime = Date.now();
  user.duration = user.endTime-user.startTime;
  user.finished = true;

  //creating an invisible element
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8, ' + encodeURIComponent(JSON.stringify(user)));
  element.setAttribute('download', user?.id+".json");
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);

}