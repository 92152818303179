import Pilz01 from '../images/pilze/pilz-01.png';
import Pilz02 from '../images/pilze/pilz-02.png';
import Pilz03 from '../images/pilze/pilz-03.png';
import Pilz04 from '../images/pilze/pilz-04.png';
import Pilz05 from '../images/pilze/pilz-05.png';
import Pilz06 from '../images/pilze/pilz-06.png';
import Pilz07 from '../images/pilze/pilz-07.png';
import Pilz08 from '../images/pilze/pilz-08.png';
import Pilz09 from '../images/pilze/pilz-09.png';
import Pilz10 from '../images/pilze/pilz-10.png';
import Pilz11 from '../images/pilze/pilz-11.png';
import Pilz12 from '../images/pilze/pilz-12.png';
import Pilz13 from '../images/pilze/pilz-13.png';
import Pilz14 from '../images/pilze/pilz-14.png';
import Pilz15 from '../images/pilze/pilz-15.png';
import { groups } from '../utils/HandleDataStorage';

export type SinglePredictionType = {
  probability: number,
  class: string,
  nearestImage: string
}

export type User = {
  id: string,
  finished: boolean,
  group: number,
  newlyCreated: boolean
}


export const giftig = 'giftig'
export const essbar = 'essbar'



export function getHandy(id: number, language: string, group: number) {
  const expl = groups[group];//group ? "expl" : "noexpl";
  const lang = language === "de" ? "DE" : "EN";
  const idstring = `${id}`.padStart(2, "0");
  return require(`../images/pilze/${expl}_${idstring}_${lang}.png`).default
  // if (language === 'de') {
  //   return DE[id - 1]
  // } else {
  //   return EN[id - 1]
  // }
}



export function getMushroomImage(id: number, language: string) {
  switch (id) {
    case 1:
      return Pilz01;
    case 2:
      return Pilz02;
    case 3:
      return Pilz03;
    case 4:
      return Pilz04;
    case 5:
      return Pilz05;
    case 6:
      return Pilz06;
    case 7:
      return Pilz07;
    case 8:
      return Pilz08;
    case 9:
      return Pilz09;
    case 10:
      return Pilz10;
    case 11:
      return Pilz11;
    case 12:
      return Pilz12;
    case 13:
      return Pilz13;
    case 14:
      return Pilz14;
    case 15:
      return Pilz15;
    default:
      return Pilz01;
  }
}