import "./survey.css"
import { Box, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BG_transparent } from '../constants';
import ImgSurvey from  '../images/Hand-Paper-Frame.png';
import { useStyles } from './IntroductionGame';
import { NextButton } from './NextButton';
import { TranslationsWithMarkup } from './TranslationsWithMarkup';
import { logSurvey } from "../utils/HandleDataStorage";

type SurveyProps = {
    onStart: any,
    userid: string,
    survey: string
}

  
const EmbedSurvey = (props: SurveyProps) => {

    const onStart = () => {
        props.onStart();
    }
    
    React.useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const skipSurvey = urlParams.get('skipSurvey')
        
        if(skipSurvey === "true"){
            onStart();
            return;
        }
    // eslint-disable-next-line
    }, []);

    // skip pre survey
    return <>
        {props.survey === "pre" && <DatenschutzScreen onNext={onStart}></DatenschutzScreen>}
        {props.survey === "post" && <DemographicSurvey onNext={onStart} userid={props.userid}></DemographicSurvey>}
    </>
};

const DemographicSurvey = (props: {onNext: any, userid: string}) => {
    const { t } = useTranslation();

    const [selectedValueTrust1, setSelectedValueTrust1] = React.useState<string>();
    const [selectedValueTrust2, setSelectedValueTrust2] = React.useState<string>();
    const [selectedGender, setSelectedGender] = React.useState<string>();
    const [selectedAge, setSelectedAge] = React.useState<number>();
    const [selectedEducation, setSelectedEducation] = React.useState<string>();
    const [selectedCountry, setSelectedCountry] = React.useState<string>();
    const [selectedPlayAlone, setSelectedPlayAlone] = React.useState<string>();
    const [freeText, setFreeText] = React.useState<string>();

    let age_list = []
    for(let i = 15; i < 100; i++){
        age_list.push(<MenuItem key={i} value={i}>{i}</MenuItem>)
    }

    
    const onClickNext = () => {
        logSurvey(props.userid, {
            trust1: selectedValueTrust1,
            trust2: selectedValueTrust2,
            gender: selectedGender,
            age: selectedAge,
            education: selectedEducation,
            country: selectedCountry,
            playAlone: selectedPlayAlone,
            freeText: freeText
        })
        props.onNext()
    }
    

    const handleChangeTrust1 = (event: any) => {
        setSelectedValueTrust1(event.target.value);
    };
    const handleChangeTrust2 = (event: any) => {
        setSelectedValueTrust2(event.target.value);
    };
    const handleChangeGender = (event: any) => {
        setSelectedGender(event.target.value);
    };
    const handleChangeAge = (event: any) => {
        setSelectedAge(event.target.value);
    };
    const handleChangeEducation = (event: any) => {
        setSelectedEducation(event.target.value);
    };
    const handleChangeCountry = (event: any) => {
        setSelectedCountry(event.target.value);
    };
    const handleChangePlayAlone = (event: any) => {
        setSelectedPlayAlone(event.target.value);
    };
    const handleChangeFreeText = (event: any) => {
        setFreeText(event.target.value);
    };

    return <div style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: BG_transparent,
        color: "black"
      }}>
          <div
            style={{
            width: '100%',
            height: '100%',
            backgroundImage: `url("${ImgSurvey}")`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center' 
            }}
            >
                <div style={{
                    height:"80%",
                    width:"65%",
                    marginTop: '8vh',
                    marginLeft: '27vw',
                    border: 'none',
                    overflowY: "auto"
                }}>
                    <Typography style={{ fontSize: '4.5vh', fontWeight: 900 }}><TranslationsWithMarkup txtkey={"survey.danke"}></TranslationsWithMarkup></Typography>
                    <Typography style={{ fontSize: '2vh', fontWeight: 100 }}><TranslationsWithMarkup txtkey={"survey.danketxt"}></TranslationsWithMarkup></Typography>
                    
                    <Typography style={{ fontSize: '3.5vh', fontWeight: 900, paddingTop: '6vh' }}><TranslationsWithMarkup txtkey={"survey.aussagezustimmung"}></TranslationsWithMarkup></Typography>
                    <Grid container style={{textAlign: "center"}}>
                        <Grid item sm={3}></Grid>
                        <Grid container item sm={9} spacing={0}
                            alignItems="center"
                            justify="center">
                            <Grid item sm={2}><Typography style={{ fontSize: '2vh', fontWeight: 100 }}>{t("survey.zustimmung1")}</Typography></Grid>
                            <Grid item sm={2}><Typography style={{ fontSize: '2vh', fontWeight: 100 }}>{t("survey.zustimmung2")}</Typography></Grid>
                            <Grid item sm={2}><Typography style={{ fontSize: '2vh', fontWeight: 100 }}>{t("survey.zustimmung3")}</Typography></Grid>
                            <Grid item sm={2}><Typography style={{ fontSize: '2vh', fontWeight: 100 }}>{t("survey.zustimmung4")}</Typography></Grid>
                            <Grid item sm={2}><Typography style={{ fontSize: '2vh', fontWeight: 100 }}>{t("survey.zustimmung5")}</Typography></Grid>
                        </Grid>
                    </Grid>
                    <Grid container style={{textAlign: "center"}}>
                        <Grid item sm={3}>
                            <Typography style={{ fontSize: '2vh', fontWeight: 100 }}><TranslationsWithMarkup txtkey={"survey.trust1"}></TranslationsWithMarkup></Typography>
                        </Grid>
                        <Grid container item sm={9} spacing={0}
                            alignItems="center"
                            justify="center">
                            <Grid item sm={2}>
                                <Radio
                                color='secondary'
                                checked={selectedValueTrust1 === "1"}
                                onChange={handleChangeTrust1}
                                value={"1"}
                                name="radio-buttons"
                                inputProps={{ 'aria-label': t("survey.zustimmung1") }}
                                />
                            </Grid>
                            <Grid item sm={2}>
                                <Radio
                                checked={selectedValueTrust1 === "2"}
                                onChange={handleChangeTrust1}
                                value={"2"}
                                name="radio-buttons"
                                inputProps={{ 'aria-label': t("survey.zustimmung2") }}
                                />
                            </Grid>
                            <Grid item sm={2}>
                                <Radio
                                    checked={selectedValueTrust1 === "3"}
                                    onChange={handleChangeTrust1}
                                    value={"3"}
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': t("survey.zustimmung3") }}
                                />
                            </Grid>
                            <Grid item sm={2}>
                                <Radio
                                    checked={selectedValueTrust1 === "4"}
                                    onChange={handleChangeTrust1}
                                    value={"4"}
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': t("survey.zustimmung4") }}
                                />
                            </Grid>
                            <Grid item sm={2}>
                                <Radio
                                    checked={selectedValueTrust1 === "5"}
                                    onChange={handleChangeTrust1}
                                    value={"5"}
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': t("survey.zustimmung5") }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container style={{textAlign: "center"}}>
                        <Grid item sm={3}>
                            <Typography style={{ fontSize: '2vh', fontWeight: 100 }}><TranslationsWithMarkup txtkey={"survey.trust2"}></TranslationsWithMarkup></Typography>
                        </Grid>
                        <Grid container item sm={9} spacing={0}
                            alignItems="center"
                            justify="center">
                            <Grid item sm={2}>
                                <Radio
                                color='secondary'
                                checked={selectedValueTrust2 === "1"}
                                onChange={handleChangeTrust2}
                                value={"1"}
                                name="radio-buttons"
                                inputProps={{ 'aria-label': t("survey.zustimmung1") }}
                                />
                            </Grid>
                            <Grid item sm={2}>
                                <Radio
                                checked={selectedValueTrust2 === "2"}
                                onChange={handleChangeTrust2}
                                value={"2"}
                                name="radio-buttons"
                                inputProps={{ 'aria-label': t("survey.zustimmung2") }}
                                />
                            </Grid>
                            <Grid item sm={2}>
                                <Radio
                                    checked={selectedValueTrust2 === "3"}
                                    onChange={handleChangeTrust2}
                                    value={"3"}
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': t("survey.zustimmung3") }}
                                />
                            </Grid>
                            <Grid item sm={2}>
                                <Radio
                                    checked={selectedValueTrust2 === "4"}
                                    onChange={handleChangeTrust2}
                                    value={"4"}
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': t("survey.zustimmung4") }}
                                />
                            </Grid>
                            <Grid item sm={2}>
                                <Radio
                                    checked={selectedValueTrust2 === "5"}
                                    onChange={handleChangeTrust2}
                                    value={"5"}
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': t("survey.zustimmung5") }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Typography style={{ fontSize: '3.5vh', fontWeight: 900, paddingTop: '6vh' }}><TranslationsWithMarkup txtkey={"survey.gender"}></TranslationsWithMarkup></Typography>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={selectedGender}
                        name="radio-buttons-group"
                    >
                        <FormControlLabel value="1" control={<Radio />} label={<Typography style={{ fontSize: '2vh', fontWeight: 100 }}>{t("survey.female")}</Typography>} onChange={handleChangeGender} />
                        <FormControlLabel value="2" control={<Radio />} label={<Typography style={{ fontSize: '2vh', fontWeight: 100 }}>{t("survey.male")}</Typography>} onChange={handleChangeGender} />
                        <FormControlLabel value="3" control={<Radio />} label={<Typography style={{ fontSize: '2vh', fontWeight: 100 }}>{t("survey.divers")}</Typography>} onChange={handleChangeGender} />
                        <FormControlLabel value="4" control={<Radio />} label={<Typography style={{ fontSize: '2vh', fontWeight: 100 }}>{t("survey.other")}</Typography>} onChange={handleChangeGender} />
                        <FormControlLabel value="5" control={<Radio />} label={<Typography style={{ fontSize: '2vh', fontWeight: 100 }}>{t("survey.nogender")}</Typography>} onChange={handleChangeGender} />
                    </RadioGroup>

                    <Typography style={{ fontSize: '3.5vh', fontWeight: 900, paddingTop: '6vh' }}><TranslationsWithMarkup txtkey={"survey.age"}></TranslationsWithMarkup></Typography>
                    <Box width={"50%"}>
                        <FormControl fullWidth>
                            <InputLabel id="select-age">{t("survey.choose")}</InputLabel>
                            <Select
                                labelId="select-age"
                                value={selectedAge}
                                // label="Age"
                                onChange={handleChangeAge}
                                >
                                {age_list}
                            </Select>
                        </FormControl>
                    </Box>

                    <Typography style={{ fontSize: '3.5vh', fontWeight: 900, paddingTop: '6vh' }}><TranslationsWithMarkup txtkey={"survey.education"}></TranslationsWithMarkup></Typography>
                    <RadioGroup
                        value={selectedEducation}
                    >
                        <FormControlLabel value="1" control={<Radio />} label={<Typography style={{ fontSize: '2vh', fontWeight: 100 }}>{t("survey.education1")}</Typography>} onChange={handleChangeEducation} />
                        <FormControlLabel value="2" control={<Radio />} label={<Typography style={{ fontSize: '2vh', fontWeight: 100 }}>{t("survey.education2")}</Typography>} onChange={handleChangeEducation} />
                        <FormControlLabel value="3" control={<Radio />} label={<Typography style={{ fontSize: '2vh', fontWeight: 100 }}>{t("survey.education3")}</Typography>} onChange={handleChangeEducation} />
                        <FormControlLabel value="4" control={<Radio />} label={<Typography style={{ fontSize: '2vh', fontWeight: 100 }}>{t("survey.education4")}</Typography>} onChange={handleChangeEducation} />
                        <FormControlLabel value="5" control={<Radio />} label={<Typography style={{ fontSize: '2vh', fontWeight: 100 }}>{t("survey.education5")}</Typography>} onChange={handleChangeEducation} />
                        <FormControlLabel value="6" control={<Radio />} label={<Typography style={{ fontSize: '2vh', fontWeight: 100 }}>{t("survey.education6")}</Typography>} onChange={handleChangeEducation} />
                        <FormControlLabel value="7" control={<Radio />} label={<Typography style={{ fontSize: '2vh', fontWeight: 100 }}>{t("survey.education7")}</Typography>} onChange={handleChangeEducation} />
                    </RadioGroup>

                    <Typography style={{ fontSize: '3.5vh', fontWeight: 900, paddingTop: '6vh' }}><TranslationsWithMarkup txtkey={"survey.country"}></TranslationsWithMarkup></Typography>
                    <RadioGroup
                        value={selectedCountry}
                    >
                        <FormControlLabel value="1" control={<Radio />} label={<Typography style={{ fontSize: '2vh', fontWeight: 100 }}>{t("survey.at")}</Typography>} onChange={handleChangeCountry} />
                        <FormControlLabel value="2" control={<Radio />} label={<Typography style={{ fontSize: '2vh', fontWeight: 100 }}>{t("survey.ch")}</Typography>} onChange={handleChangeCountry} />
                        <FormControlLabel value="3" control={<Radio />} label={<Typography style={{ fontSize: '2vh', fontWeight: 100 }}>{t("survey.de")}</Typography>} onChange={handleChangeCountry} />
                        <FormControlLabel value="4" control={<Radio />} label={<Typography style={{ fontSize: '2vh', fontWeight: 100 }}>{t("survey.otherEU")}</Typography>} onChange={handleChangeCountry} />
                        <FormControlLabel value="5" control={<Radio />} label={<Typography style={{ fontSize: '2vh', fontWeight: 100 }}>{t("survey.otherNonEU")}</Typography>} onChange={handleChangeCountry} />
                    </RadioGroup>

                    <Typography style={{ fontSize: '3.5vh', fontWeight: 900, paddingTop: '6vh' }}><TranslationsWithMarkup txtkey={"survey.alleineGespielt"}></TranslationsWithMarkup></Typography>
                    <RadioGroup
                        value={selectedPlayAlone}
                    >
                        <FormControlLabel value="1" control={<Radio />} label={<Typography style={{ fontSize: '2vh', fontWeight: 100 }}>{t("survey.alone")}</Typography>} onChange={handleChangePlayAlone} />
                        <FormControlLabel value="2" control={<Radio />} label={<Typography style={{ fontSize: '2vh', fontWeight: 100 }}>{t("survey.together")}</Typography>} onChange={handleChangePlayAlone} />
                    </RadioGroup>

                    <Typography style={{ fontSize: '3.5vh', fontWeight: 900, paddingTop: '6vh' }}><TranslationsWithMarkup txtkey={"survey.freetext"}></TranslationsWithMarkup></Typography>
                    <Box width={"70%"}>
                        <TextField
                            id="outlined-multiline-flexible"
                            rows={4}
                            multiline
                            fullWidth
                            value={freeText}
                            onChange={handleChangeFreeText}
                        />
                    </Box>

                </div> 
                <NextButton disabled={
                    !(selectedValueTrust1 != null && selectedValueTrust2 != null && selectedGender != null && selectedAge != null && selectedEducation != null && selectedCountry != null && selectedPlayAlone != null)
                } style={{
                    position: 'absolute',
                    right: '15vh',
                    bottom: '5vh',
                    border: "1px solid grey",
                    borderRadius: "2vh",
                    backgroundColor: "white",
                    paddingBottom: "0px",
                    paddingTop: "0px"
                }} onClick={onClickNext}></NextButton>

      </div>
        
    </div> 
}



const DatenschutzScreen = (props: {onNext: any}) => {
    const classes = useStyles();

    return <div className={classes.container}>
        <div className={classes.infoText} >
            <div style={{textAlign: 'center', paddingTop: '4vh', paddingLeft: '4vh', paddingRight: '4vh'}}>
                <Typography style={{ fontSize: '4.5vh', fontWeight: 900 }}><TranslationsWithMarkup txtkey={"datenschutzHeadline"}></TranslationsWithMarkup></Typography>
                <Typography style={{ fontSize: '2vh', fontWeight: 100 }}><TranslationsWithMarkup txtkey={"datenschutzText"}></TranslationsWithMarkup></Typography>
                <Typography style={{ fontSize: '3.0vh', marginTop: '3vh', fontWeight: 900 }}><TranslationsWithMarkup txtkey={"teilnahmeHeadline"}></TranslationsWithMarkup></Typography>
                <Typography style={{ fontSize: '2vh', fontWeight: 700 }}><TranslationsWithMarkup txtkey={"teilnahmeText"}></TranslationsWithMarkup></Typography>
                <Typography style={{ fontSize: '3.0vh', marginTop: '3vh', fontWeight: 900 }}><TranslationsWithMarkup txtkey={"einwilligungHeadline"}></TranslationsWithMarkup></Typography>
                <Typography style={{ fontSize: '2vh', fontWeight: 700 }}><TranslationsWithMarkup txtkey={"einwilligungText"}></TranslationsWithMarkup></Typography>
                <Typography style={{ fontSize: '3.0vh', marginTop: '3vh', fontWeight: 900 }}><TranslationsWithMarkup txtkey={"kontaktHeadline"}></TranslationsWithMarkup></Typography>
                <Typography style={{ fontSize: '2vh', fontWeight: 700 }}><TranslationsWithMarkup txtkey={"kontaktText"}></TranslationsWithMarkup></Typography>
            </div>
        <NextButton style={{
            position: 'absolute',
            right: '4vh',
            bottom: '4vh'
        }} onClick={props.onNext}></NextButton>
        </div>
    </div>
}

export default EmbedSurvey;

