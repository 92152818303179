import React, { Suspense, useEffect, useState } from 'react';
import './App.css';
import { Button, Grid } from '@material-ui/core';
import { User } from './types/types';
import { TabletFinalize } from './components/Finalize';
import CircularProgress from '@material-ui/core/CircularProgress';
import EmbedSurvey from './components/EmbedSurvey';

import BtnDE1 from './images/Start_Language-DE-1.png';
import BtnDE2 from './images/Start_Language-DE-2.png';
import BtnEN1 from './images/Start_Language-EN-1.png';
import BtnEN2 from './images/Start_Language-EN-2.png';
import BtnStart from './images/Start-Button-Logo_02.png';

import './i18nextConf';
import { useTranslation } from 'react-i18next';
import { ParalaxBack } from './components/ParalaxBack';
import { BG_transparent } from './constants';
import IntroductionGame from './components/IntroductionGame';
import { TabletTasks } from './components/Tasks';
import { classifyAndNext, getNextPrediction, getUserId, getUser, removeUser, downloadResult, logStart } from './utils/HandleDataStorage';
import { useIdleTimer } from 'react-idle-timer';



export enum AppState {
  Introduction,
  Survey,
  IntroductionGame,
  Tasks,
  TakingPhoto,
  Finish,
  SurveyPost
}



function TabletIntroduction({ onStart }: IntroductionProps) {
  const { i18n } = useTranslation();

  const [srcDE, setSrcDE] = useState("");
  const [srcEN, setSrcEN] = useState("");

  const change_lang = (lang: string) => {
    i18n.changeLanguage(lang);
  

    setSrcDE(lang === "de" ? BtnDE2 : BtnDE1);
    setSrcEN(lang === "en" ? BtnEN2 : BtnEN1)

  };

  useEffect(() => {
    change_lang("de");
    // eslint-disable-next-line
  }, []);


  
  return <Grid container style={{
    backgroundColor: BG_transparent,
    height: "100%",
    textAlign: "center"
  }} >
    <Grid item xs={2}></Grid>
    <Grid item xs={8} style={{ marginTop: "15vh" }}>
      <div
        style={{
          margin: "auto",
          width: '35vw',
          height: '80%',
          backgroundImage: `url("${BtnStart}")`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundPosition: 'center'
        }}>
        <Button onClick={() => onStart(i18n.language)} variant="text" style={{
          // borderRadius: '1rem',
          // background: 'white',
          color: 'black',
          fontWeight: 1000,
          fontSize: '8vh',
          paddingTop: '2vh',
          paddingBottom: '2vh',
          paddingLeft: '5vh',
          paddingRight: '5vh',
          marginTop: '35.5vh',
        }}>START</Button>
      </div>
    </Grid>
    <Grid item xs={2}>
      <div style={{
        marginTop: "5px",
        marginRight: "20px",
        width: "100%",
        height: "100%",
      }}>
        <img alt={"Deutsch"} className={"lang_icon"} src={srcDE} onClick={() => { change_lang("de") }}></img>
        <img alt={"English"} className={"lang_icon"} src={srcEN} onClick={() => { change_lang("en") }}></img>
      </div>
    </Grid>
  </Grid>
}

function App() {
  
  let [availableMushrooms, setAvailableMushrooms] = React.useState(Array.from(Array(31).keys()).map((v, i) => { return "HOXAI-"+String(v).padStart(3, '0');} )) //HOXAI-001
  let [appState, setAppState] = React.useState(AppState.Introduction)
  let [predictionId, setPredictionId] = React.useState<number>()
  let [user, setUser] = React.useState<User>()
  let [step, setStep] = React.useState({
    step: 0,
    maxSteps: 0
  })
  let [loading, setLoading] = React.useState(true)

  let [took, setTook] = React.useState(0);

  const onIdle = () => {
    onBackToStart()
  }

  // https://idletimer.dev/docs/api/use-idle-timer
  useIdleTimer({ 
    onIdle: onIdle, 
    // onPrompt: () => {console.log("prompt")},
    // promptTimeout: 1000*10,
    timeout: 1000*2*60, 
  })


  const [finishAnimation, setFinishAnimation] = React.useState<'in' | 'out' | 'stay' | 'none' | 'dismiss'>('none');
 
  const onBackToStart = () => {
    downloadResult(user?.id)
    removeUser(user?.id);

    setAvailableMushrooms(Array.from(Array(31).keys()).map((v, i) => { return "HOXAI-"+String(v).padStart(3, '0');} ))
    setAppState(AppState.Introduction)
    setPredictionId(undefined)
    setUser(undefined)
    setStep({
      step: 0,
      maxSteps: 0
    })
    setLoading(true)
    setTook(0)

    onInit()
  }

  const onClassify = (value: boolean[]) => {
    setLoading(true)

    if (value[1]) {
      setTook(took + 1);
    }
    if (step.step === step.maxSteps) {
      // Finish
      classifyAndNext(user?.id as string, step.step, value).then(() => {
        setLoading(false)
        setAppState(AppState.SurveyPost)
      })
    } else {
      classifyAndNext(user?.id as string, step.step, value).then(() => {
        setLoading(false)
        setAppState(AppState.TakingPhoto)
      })
      

    }
  }

  const onScan = (mushroomCode:string) => {
    setAvailableMushrooms((items) => items.filter((val, i) => val !== mushroomCode))
    setLoading(true)
    setAppState(AppState.Tasks)
    getNextPrediction(user?.id as string).then((r) => {
      setLoading(false)
      setPredictionId(r.predictionId)
      setStep({
        step: r.step,
        maxSteps: r.maxSteps
      })
    })
  }

  const onInit = () => {
    getUser(getUserId()).then(response => {
      setUser(response)

      if (response.finished) {
        // User has finished -> post survey
        setAppState(AppState.SurveyPost)
        setLoading(false)
      } else if (response.newlyCreated) {
        // User was newly created -> intro
        setAppState(AppState.Introduction)
        setLoading(false)
      } else {
        // User is in middle of tasks -> camera/tasks
        setLoading(false)
        setAppState(AppState.TakingPhoto)
        
      }
    })
  }

  React.useEffect(() => {
    onInit()
    // eslint-disable-next-line
  }, [])

  

  const onStartSurvey = (lang?:string) => {
    logStart(user?.id, lang)
    setLoading(false)
    setAppState(AppState.Survey)
  }

  const onStartIntroToGame = () => {
    setAppState(AppState.IntroductionGame)
  }
  const onFinishGame = () => {
    setAppState(AppState.Finish)
  }

  const onStartTasks = () => { // after survey completed
    setLoading(false)
    setAppState(AppState.TakingPhoto)
  }

  // https://medium.com/@gocekabov/react-idle-timer-e0708f73fb5b
  return <>
      <div style={{
        width: '100%',
        height: '100%',
        zIndex: 0,
        position: 'relative'
      }}>
        {/* <SearchIcon style={{display:"absolute", left:"0px", right:"0px"}} onClick={() => {init_magnify()}}></SearchIcon> */}
        <ParalaxBack finishAnimation={finishAnimation}></ParalaxBack>

        <div style={{ width: '100%', height: '100%' }}>
          {appState === AppState.Introduction && <Suspense fallback={<div>loading</div>}><TabletIntroduction onStart={onStartSurvey}></TabletIntroduction></Suspense>}
          {appState === AppState.Survey && user && <Suspense fallback={<div>loading</div>}><EmbedSurvey onStart={onStartIntroToGame} userid={user.id} survey={"pre"}></EmbedSurvey></Suspense>}
          {appState === AppState.IntroductionGame && user && <Suspense fallback={<div>loading</div>}><IntroductionGame onStart={onStartTasks} group={user.group}></IntroductionGame></Suspense>}
          {(appState === AppState.Tasks || appState === AppState.TakingPhoto) && user && <Suspense fallback={<div>loading</div>}><TabletTasks availableMushrooms={availableMushrooms} appState={appState} onScan={onScan} predictionId={predictionId} onClassify={onClassify} step={step} user={user} took={took} finishAnimation={finishAnimation} setFinishAnimation={setFinishAnimation}></TabletTasks></Suspense>}
          {appState === AppState.SurveyPost && user && <Suspense fallback={<div>loading</div>}><EmbedSurvey onStart={onFinishGame} userid={user.id} survey={"post"}></EmbedSurvey></Suspense>}
          {appState === AppState.Finish && <Suspense fallback={<div>loading</div>}><TabletFinalize backToStart={onBackToStart} userId={user?.id}></TabletFinalize></Suspense>}
        </div>

        {
          loading && <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            position: 'absolute',
            zIndex: 10
          }}>
            <CircularProgress size='8rem' />
          </div>
        }
      </div>
    </>
  
}

type IntroductionProps = {
  onStart: (lang?:string) => void
}


export default App;



