import React from 'react';
import { Button, Dialog } from '@material-ui/core';
import ScanHand from '../images/ScanHand-Mobile-Frame.png';
import QrScanner from 'qr-scanner';
import MushroomError_DE from '../images/mushroom-error_DE.png';
import MushroomError_EN from '../images/mushroom-error_EN.png';
import { useTranslation } from 'react-i18next';
import { BG_transparent } from '../constants';
import { NextButton } from './NextButton';
import { DEV_MODE } from '../utils/HandleDataStorage';


// https://github.com/nimiq/qr-scanner/blob/34bccc6b278672e28d6eb62f07c1832f1e6d2e92/src/qr-scanner.ts#L751
// QrScanner.WORKER_PATH = '/static/qr-scanner-worker.min.js';

type TakePhotoProps = {
  onScan: (mushroomCode: string) => void;
  resultList?: string[];
  openErrorDialog?: boolean;
};

export function TakePhoto({ onScan, resultList, openErrorDialog }: TakePhotoProps) {
    const { i18n } = useTranslation();
  const [hasCam, setHasCam] = React.useState(true);

  const [dialogOpen, setDialogOpen] = React.useState(false);

  // const calculateScanRegion = (video: any) => {
  //   const height = 400; //video.videoHeight;
  //   const width = height * 0.5;
  //   console.log(height, width);
  //   return {
  //     x: Math.round((video.videoWidth - width) / 2),
  //     y: Math.round((video.videoHeight - height) / 2),
  //     width: width,
  //     height: height,
  //     downScaledWidth: width,
  //     downScaledHeight: height,
  //   };
  // };


  React.useEffect(() => {


    
    if(DEV_MODE){
      onScan(resultList != null ? resultList[0] : "");
    }else{
      QrScanner.hasCamera().then((value) => {
        if (value) {
          setHasCam(true);
          console.log(navigator.mediaDevices.getSupportedConstraints())
          // webcam is available
          const elem = document.getElementById('videoElem') as HTMLVideoElement;
          if (elem) {
            //@ts-ignore
            const qrScanner = new QrScanner(elem, (result: any) => {
              if (result) {
                if (resultList) { // if result list is not given, any qr code is ok
                  if (resultList.includes(result)) { //otherwise, we only go to the next, if the result is included in the result list
                    onScan(result);
                    qrScanner.destroy();
                  } else {
                    if (openErrorDialog)
                      setDialogOpen(true);
                  }
                } else {
                  onScan(result);
                  qrScanner.destroy();
                }
              }
            // }, undefined, calculateScanRegion, 'environment'); //calculateScanRegion
            }//, {
              // calculateScanRegion: calculateScanRegion, 
              // preferredCamera: 'environment'
              // highlightScanRegion: true
            // }
            ); //calculateScanRegion
            // console.log(qrScanner)
            qrScanner.start().then(() => {
              //@ts-ignore
              // const track = qrScanner.$video.srcObject.getVideoTracks()[0];
              // console.log(track)
              // console.log(track.getSettings())
              // console.log(track.getConstraints())
              // console.log(track.getCapabilities())
              // console.log(track.getCapabilities().focusDistance)
              // // console.log(track.getCapabilities().exposureCompensation)
              // // console.log(track.getCapabilities().exposureTime)
              // console.log(track.getCapabilities().focusMode)
              // // https://developer.mozilla.org/en-US/docs/Web/API/MediaTrackConstraints
              // // https://developer.mozilla.org/en-US/docs/Web/API/Media_Capture_and_Streams_API/Constraints
              // // https://webrtchacks.com/bad-lighting-fix-with-javascript-webcam-exposure/
              // // https://w3c.github.io/mediacapture-image/#exposure
              // track.applyConstraints({
              //   // focusMode: "manual",
              //   // focusDistance: 4.2
              //   advanced: [
              //     // {exposureMode: "manual", exposureTime: 1}
              //     // {focusMode: "manual", focusDistance: 4.2}
              //     {pointsOfInterest: {x:0., y:0.}}
              //   ]
              // }).then(() => {
              //   console.log("ok", track.getSettings())
              //   console.log("ok", track.getConstraints())
              // }).catch((e:any) => {
              //   console.log("catch", e)
              // });
            });
  
            const canvasElem = document.getElementById('canvasElem') as HTMLCanvasElement;
            if (canvasElem) {
              //@ts-ignore
              qrScanner.$canvas = canvasElem;
            }
  
  
          }
        } else {
          setHasCam(false);
        }
      });
    }

    // eslint-disable-next-line
  }, []);

  return <>
    <div style={{
      width: 0,
      flex: 1,
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}>
        <div style={{
          backgroundImage: `url("${ScanHand}")`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          width: '76.3vh',
          height: '100vh',
          position: 'absolute'
        }}>
          <div style={{
              width: "43vh",
              height: "89vh",
              zIndex: -3,
              // transform: "scaleX(-1)",
              position: 'absolute',
              left: '21.3vh',
              top: '7.5vh',
              backgroundColor: "grey"
            }}>
            <video id='videoElem' style={{
              display: "none"
            }}></video>
            <canvas id='canvasElem' style={{
              width: "43vh",
              // height: "89vh",
              height: "45vh",
              zIndex: -3,
              // transform: "scaleX(-1)",
              position: 'absolute',
              // left: '21.3vh',
              // top: '7.5vh',
              top: '20vh',
            }}></canvas>
          </div>
        </div>


        {!hasCam && <Button style={{
          position: 'absolute', zIndex: 5
        }} onClick={() => onScan("")}>Scan</Button>}

      </div>
      <Dialog PaperProps={{
        style: {
          backgroundColor: BG_transparent,
        },
      }} onClose={() => { setDialogOpen(false); }} aria-labelledby="simple-dialog-title" open={dialogOpen}>
        <img alt={"You already made a decision for this mushroom."} style={{ width: "50vw" }} src={i18n.language === "de" ? MushroomError_DE : MushroomError_EN} />
        <NextButton style={{
          position: 'absolute',
          right: '4vh',
          bottom: '4vh'
        }}
          onClick={() => { setDialogOpen(false); }} text="OK"></NextButton>
      </Dialog>
    </div>
  </>;
}
