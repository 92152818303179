import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import BtnNext from  '../images/Button-White-2.png';

const useStyles = makeStyles({
    btnNext: {
        backgroundImage: `url("${BtnNext}")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        width:"fit-content",
        fontSize: '6vh',
        color: 'black',
        padding: "3vh",
        float: "right",
        cursor: "pointer",
        userSelect: "none",
    }
})

export function NextButton({ onClick, style, text, disabled }: { onClick: () => void, style?: any, text?: string, disabled?: boolean  }) {
    const classes = useStyles();
    const { t } = useTranslation();

    if(!text){
        text = "next";
    }

    if(disabled){
        style["color"] = "grey";
    }
    
    return <div className={classes.btnNext} style={style} onClick={() => {if(!disabled){onClick()}}}>{t(text)}</div>;
}